<template>
  <div v-if="!loading">
    <div class="d-flex  flex-sm-col-reverse flex-lg-row mx-0 py-4 justify-content-space-evenly">
      <div class="col-10 px-0 flex-grow-1">
        <div class="d-flex flex-column bg-white-gray-text px-4 pb-3 h-100">
          <h5 class="text-black mr-auto my-4">Create resources</h5>
          <div>
            <div class="d-flex flex-column">
              <company-selector
                @company-changed="selectCompany($event)"
                :loading="loading"
                :options="companies"
                :class="'mr-auto mb-2 resource-selector ml-2'">
              </company-selector>
              <device-selector
                v-if="selectedCompany && selectedCompany.devices" @device-changed="selectDevice($event)"
                :loading="loading"
                :options="selectedCompany.devices"
                :initial-device="selectedDevice"
                :classProp="'mr-auto mb-2 resource-selector ml-2'" >
              </device-selector>
              <p class="text-left ml-3 text-muted" v-else-if="selectedCompany && selectedCompany.devices.length < 1">The selected company has no devices</p>
              <p class="text-left ml-3 text-muted" v-else> Select a company to see it's devices</p>
            </div>
            <div class="row m-0 mb-2">
              <div class="col-12 align-text-left">
                <label class="my-auto mr-2" for="fromDate">Start date</label>
                <calendar id="fromDate" :class="toDate && fromDate > toDate ? 'p-invalid' : ''"
                          v-model="fromDate"
                          :showTime="true"
                          :show-seconds="true"
                          :hide-on-date-time-select="true"
                          :stepSecond="5"
                          dateFormat="dd/mm/yy"
                />
              </div>
              <div class="col-12 align-text-left my-2">
                <label class="my-auto mr-3" for="toDate">End date</label>
                <calendar id="toDate" class="mr-2"
                          :class="fromDate && fromDate > toDate ? 'p-invalid' : ''"
                          v-model="toDate"
                          :showTime="true"
                          :show-seconds="true"
                          :hide-on-date-time-select="true"
                          :stepSecond="5"
                          dateFormat="dd/mm/yy"
                />
              </div>
              <div class="col-12 align-text-left my-2">
                <label class="my-auto mr-3" for="dpLimit">Datapoints limit</label>
                <input type="number" id="dpLimit" class="mr-2"
                          min="0"
                          max="1000"
                          v-model="dataPointsLimit"
                />
              </div>
              <div v-if="selectedDevice" class="col-12 align-text-left my-2">
                <div class="d-flex">
                  <label class="my-auto mb-3" for="sensorBox">Select sensors or leave empty to get data for all sensors</label>
                  <a @click="resetSelectedSensors()" class="ml-auto">Reset</a>
                </div>
                <list-box id="sensorBox" v-model="selectedSensors"  listStyle="height:250px" :options="selectedDevice.sensors" optionValue="name" optionLabel="name" :multiple="true"/>
              </div>
              <div
                  v-if="selectedDevice && fromDate && toDate"
                  class="border w-100 mx-3 mb-4 pl-2"
              >
                <div class="text-left mt-2">
                  <pv-button
                      @click="loadImageData()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add timeseries chart"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div>
                <div class="text-left my-2">
                  <pv-button
                      @click="loadTableData()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add a table"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div>
                <div class="text-left my-2">
                  <pv-button
                      @click="getCorrelationHeatmap()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add a correlation heatmap"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div>
                <!-- <div class="text-left my-2">
                  <pv-button
                      @click="getCorrelationHeatmap()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add a correlation heatmap data table"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div> -->
                <div class="text-left my-2">
                  <pv-button
                      @click="getBoxPlots()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add boxplots for period"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div>
              </div>
              <div class="ml-auto">
                <pv-button
                    v-if="!dataLoading && telemetry"
                    @click="addImageToArchive('telemetry', output)"
                    class="btn btn-black"
                    icon="pi pi-file-archive-o"
                    label="Add to folder"
                    v-tooltip="'Adds the image to the zip. Use in order to export multiple images in one zip'"
                />
                <pv-button
                    v-if="!dataLoading && telemetry"
                    @click="exportToPng()"
                    class="btn btn-black"
                    icon="pi pi-refresh"
                    label="Export to PNG"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="sensor-chart mt-2" v-if="selectedDevice  && selectedCompany  && !chartLoading && telemetry">
              <div v-if="telemetry">
                <div ref="telemetryChart">
                  <telemetry-chart class="pt-2" :telemetry="telemetry" :alarm-selected="false" :selected-device="selectedDevice"></telemetry-chart>
                </div>
              </div>
            </div>
            <div v-else-if="chartLoading" class="d-flex flex-column bg-white-gray-text px-4 h-100">
              <h4 class="my-2">
                Loading <i class='fa fa-circle-o-notch fa-spin'></i>
              </h4>
            </div>
          </div>
          <div class="table-wrapper">
            <data-table
                v-if="sensorValues.length > 0 && !dataTableLoading"
                :value="sensorValues"
                class="p-datatable-lg my-auto"
                ref="dt"
                id="dt"
                :paginator="true"
                :rows="5"
                columns="">
              <template #header class="">
                <div class="d-flex mb-3">
                  <h5 class="ml-3 my-4">Sensor Feed</h5>
                  <div class="ml-auto my-auto">
                    <pv-button
                        v-if="!dataLoading && sensorValues.length > 0"
                        @click="addExcelToArchive()"
                        class="btn btn-black"
                        icon="pi pi-file-archive-o"
                        label="Add to folder"
                        v-tooltip="'Adds the table to the zip. Use in order to export multiple tables in one zip'"
                    />
                    <pv-button type="button" icon="pi pi-file-excel-o" @click="exportExcel(false)" class="btn btn-black mw-100" label="Export to XLSX" />
                  </div>
                </div>
              </template>
              <column field="name" header="Name"></column>
              <column field="min" class="w-25" header="Min">
              </column>
              <column field="max" header="Max"></column>
            </data-table>
            <div v-else-if="dataTableLoading">
              <hr>
              <h4 class="my-2">
                Loading <i class='fa fa-circle-o-notch fa-spin'></i>
              </h4>
            </div>
          </div>
          <div class="d-flex h-100 row" v-if="selectedDevice  && selectedCompany && (selectedDevice.lastActive || selectedDevice.error)">
            <div class="col-12 d-flex">
              <h4 class="my-auto mx-auto">There seems to be no telemetry for the period you have chosen</h4>
            </div>
            <div class="col-12 mt-auto" v-if="selectedDevice.lastActive">
              <h5 class="mx-auto" v-tooltip="'Date format used is D/M/Y throughout the website'">Device was last active on: {{ selectedDevice.lastActive }}</h5>
            </div>
          </div>

          <div v-if=correlationHeatmap>
              <div class="d-flex">
                <div class="ml-auto">
                  <pv-button
                      v-if="!dataLoading && correlationHeatmap"
                      @click="addImageToArchive('correlation-heatmap', correlationHeatmap)"
                      class="btn btn-black"
                      icon="pi pi-file-archive-o"
                      label="Add to folder"
                      v-tooltip="'Adds the image to the zip. Use in order to export multiple images in one zip'"
                  />
                  <pv-button
                      v-if="!dataLoading && correlationHeatmap"
                      @click="exportCorrelationHeatmapToPng()"
                      class="btn btn-black"
                      icon="pi pi-refresh"
                      label="Export to PNG"
                  />
                </div>
              </div>
              <img  v-tooltip="'Empty heatmap/cells mean no data for period-sensor combination'" class="mw-100" :src="`data:image/png;base64,${ correlationHeatmap }`" />
          </div>

            <div v-if=boxPlots>
              <carousel :value="boxPlots" :numVisible="1">
                <template #item="slotProps">
                  <div class="d-flex mb-2">
                    <div class="ml-auto">
                      <pv-button
                          v-if="!dataLoading && boxPlots"
                          @click="addAllBoxplotsToArchive()"
                          class="btn btn-black"
                          icon="pi pi-file-archive-o"
                          label="Add all plots to folder"
                          v-tooltip="'Adds the boxplots to the zip'"
                      />
                      <pv-button
                          v-if="!dataLoading && boxPlots"
                          @click="addImageToArchive('boxplot', slotProps.data)"
                          class="btn btn-black"
                          icon="pi pi-file-archive-o"
                          label="Add to folder"
                          v-tooltip="'Adds the image to the zip. Use in order to export multiple images in one zip'"
                      />
                      <pv-button
                          v-if="!dataLoading && boxPlots"
                          @click="exportBoxPlotToPng(slotProps.data)"
                          class="btn btn-black"
                          icon="pi pi-refresh"
                          label="Export to PNG"
                      />
                    </div>
                  </div>
                  <h2>Boxplots</h2>
                  <img class="mw-100" :src="`data:image/png;base64,${ slotProps.data }`" />
                </template>
              </carousel>
          </div>

          <div class="export-wrapper">
            <data-table
                v-if="addedZipFiles.length > 0 && !chartLoading"
                :value="addedZipFiles"
                class="p-datatable-lg my-auto"
                ref="dt"
                id="dt"
                :paginator="true"
                :rows="5"
                columns="">
              <template #header class="">
                <div class="d-flex mb-3">
                  <h5 class="ml-3 my-4">Files ready for export</h5>
                </div>
              </template>
              <column field="name" header="Name"></column>
              <column field="type" header="Type"></column>
              <column field="sensorsCount" header="Number of sensors selected">
              </column>
              <column>
                <template #body="slotProps">
                  <i @click="removeFileFromExport(slotProps.data)" class='ml-auto fa fa-times cursor-pointer'></i>
                </template>
              </column>
            </data-table>
            <pv-button
                v-if="(telemetry || sensorValues.length > 0 || correlationHeatmap || boxPlots)"
                type="button" icon="pi pi-file-archive-o"
                @click="downloadZipFile" class="btn btn-black mw-100 my-4 mx-auto"
                label="Export all resources"
                v-tooltip="addedZipFiles.length > 0 ? 'Exports all resources added to the zip/folder' : 'No files have been added to the archive yet'"
                :disabled="(addedZipFiles.length<=0 && !dataLoading)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="mt-5" v-else>
    Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
  </h4>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import JSZip from 'jszip';
import { useToast } from 'vue-toastification';
import CompanySelector from '../components/CompanySelector.vue';
import DeviceSelector from '../components/DeviceSelector.vue';
/* eslint-disable import/no-cycle */
import router from '../router';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import TelemetryChart from '../components/TelemetryChart';

const toast = useToast();

export default {
  components: {
    TelemetryChart,
    CompanySelector,
    DeviceSelector,
  },
  setup() {
    const chart = ref(null);
    const store = useStore();

    return {
      userRole: computed(() => store.state.users.userRole),
      loadDevices: () => store.dispatch('devices/loadDevices'),
      retrieveDataForPeriod: (config) => store.dispatch(
        'devices/retrieveDataForPeriod', {
          config,
        },
      ),
      retrieveCorrelationHeatmap: (config) => store.dispatch(
        'devices/getHeatMap', {
          config,
        },
      ),
      retrieveBoxPlots: (config) => store.dispatch(
        'devices/getBoxPlots', {
          config,
        },
      ),
      selectDeviceInStore: (device) => store.commit('devices/setSelectedDevice', device),
      selectedDevice: computed(() => store.state.devices.selectedDevice),
      dataLoading: computed(() => store.state.devices.devicesDataLoading),
      loadCompanies: () => store.dispatch('users/getCompanies'),
      companies: computed(() => store.state.users.companies),
      chart,
    };
  },
  data() {
    return {
      loading: true,
      boxPlots: null,
      selectedSensors: null,
      chartLoading: false,
      telemetry: null,
      dataTableLoading: false,
      output: null,
      selectedCompany: null,
      correlationHeatmap: null,
      fromDate: null,
      toDate: null,
      dataPointsLimit: 15,
      addedZipFiles: [],
      workbook: null,
      correlationHetmapOutput: null,
      zipFile: new JSZip(),
      sensorValues: [],
    };
  },
  async mounted() {
    if (this.userRole !== 'admin') {
      router.push({ name: 'Home' });
    }
    this.loading = true;
    await this.loadCompanies();
    this.loading = false;
  },
  methods: {
    async print() {
      const options = {
        type: 'dataURL',
      };
      this.output = await this.$html2canvas(this.$refs.telemetryChart, options);
    },
    async exportToPng() {
      await this.print();
      const fileLinkTemp = document.createElement('a');
      const fileName = `${this.selectedDevice.name}-telemetry-${moment(this.fromDate).format('D_MMM_YY')
      }-${
        moment(this.toDate).format('D_MMM_YY')}.png`;
      fileLinkTemp.href = this.output;
      fileLinkTemp.setAttribute('download', fileName);
      document.body.appendChild(fileLinkTemp);
      fileLinkTemp.click();
    },
    async addAllBoxplotsToArchive() {
      const folderName = `${this.selectedDevice.name}-boxplots-${moment(this.fromDate).format('D_MMM_YY-HH:mm')}-${moment(this.toDate).format('D_MMM_YY')}-${this.selectedSensors.length}`;
      for (let i = 0; i < this.boxPlots.length; i++) {
        const fileName = `${i}-${this.selectedDevice.name}-boxplot-${moment(this.fromDate).format('D_MMM_YY')
        }-${moment(this.toDate).format('D_MMM_YY')}.png`;
        this.zipFile.folder(folderName).file(fileName, this.boxPlots[i].replace(/^data:image\/(png|jpg);base64,/, ''), { base64: true });
      }

      this.addedZipFiles.push(
        {
          type: 'Boxplots Folder',
          name: folderName,
          sensorsCount: this.selectedSensors ? this.selectedSensors.length : this.selectedDevice.sensors.length,
        },
      );

      toast.success('Boxplots successfully added to folder');
    },
    async exportBoxPlotToPng(boxplot) {
      const fileLink = document.createElement('a');
      fileLink.href = `data:image/png;base64,${boxplot}`;
      const fileName = `${this.selectedDevice.name}-boxplot-${moment(this.fromDate).format('D_MMM_YY')
      }-${
        moment(this.toDate).format('D_MMM_YY')}.png`;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async exportCorrelationHeatmapToPng() {
      const fileLink = document.createElement('a');
      fileLink.href = `data:image/png;base64,${this.correlationHeatmap}`;
      const fileName = `${this.selectedDevice.name}-correlation-${moment(this.fromDate).format('D_MMM_YY')
      }-${
        moment(this.toDate).format('D_MMM_YY')}.png`;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getCorrelationHeatmap() {
      this.correlationHeatmap = null;
      const config = {
        params: {
          ts: moment(this.toDate).format('x'),
          deviceId: this.selectedDevice.uuid,
          lowerTs: moment(this.fromDate).format('x'),
          keys: this.selectedSensors ? this.selectedSensors.toString() : null,
        },
      };
      this.correlationHeatmap = await this.retrieveCorrelationHeatmap(config);
    },
    async getBoxPlots() {
      this.boxPlots = null;
      const config = {
        params: {
          ts: moment(this.toDate).format('x'),
          deviceId: this.selectedDevice.uuid,
          lowerTs: moment(this.fromDate).format('x'),
          keys: this.selectedSensors ? this.selectedSensors.toString() : null,
        },
      };
      this.boxPlots = await this.retrieveBoxPlots(config);
    },
    async loadImageData() {
      if (this.dataPointsLimit > 2000 || this.dataPointsLimit < 1) {
        toast.error('Please select a limit between 1 and 2000');
      } else {
        this.selectedDevice.lastActive = null;
        const config = {
          params: {
            ts: moment(this.toDate).format('x'),
            deviceId: this.selectedDevice.uuid,
            lowerTs: moment(this.fromDate).format('x'),
            keys: this.selectedSensors ? this.selectedSensors.toString() : null,
            limit: this.dataPointsLimit,
          },
        };
        this.chartLoading = true;
        const tempTelemetry = await this.retrieveDataForPeriod(config);
        if (Object.keys(tempTelemetry).length === 0) {
          this.telemetry = null;
          this.selectedDevice.lastActive = moment(tempTelemetry).format('DD/MM/Y HH:mm');
        } else if (Object.keys(tempTelemetry).length > 0 && !tempTelemetry.errorCode) {
          this.telemetry = tempTelemetry;
        } else {
          this.selectedDevice.error = true;
        }
        if (this.$refs.chart) {
          this.$refs.chart.refresh();
        }
        this.chartLoading = false;
      }
    },
    async loadTableData() {
      this.selectedDevice.lastActive = null;
      const config = {
        params: {
          ts: moment(this.toDate).format('x'),
          deviceId: this.selectedDevice.uuid,
          lowerTs: moment(this.fromDate).format('x'),
          keys: this.selectedSensors ? this.selectedSensors.toString() : null,
          limit: this.dataPointsLimit,
        },
      };
      this.dataTableLoading = true;
      const tempTelemetry = await this.retrieveDataForPeriod(config);
      this.sensorValues = [];
      if (Object.keys(tempTelemetry).length === 0) {
        this.selectedDevice.lastActive = moment(tempTelemetry).format('DD/MM/Y HH:mm');
      } else {
        Object.entries(tempTelemetry).map(([key]) => {
          const sensor = {};
          sensor.name = key;
          let maxVal = null;
          let minVal = null;
          Object.entries(tempTelemetry[key]).map(([, tempValue]) => {
            if (!maxVal) {
              maxVal = tempValue.value;
            }
            if (!minVal) {
              minVal = tempValue.value;
            }
            if (parseFloat(tempValue.value) > parseFloat(maxVal)) {
              maxVal = tempValue.value;
            }
            if (parseFloat(tempValue.value) < parseFloat(minVal)) {
              minVal = tempValue.value;
            }
          });
          sensor.max = maxVal;
          sensor.min = minVal;
          this.sensorValues.push(sensor);
        });
      }
      this.dataTableLoading = false;
    },
    removeFileFromExport(event) {
      if (event.type === 'png') {
        delete this.zipFile.files[event.name];
      } else if (event.type === 'XLSX Sheet') {
        delete this.workbook.Sheets[event.name];
        this.workbook.SheetNames.splice(this.workbook.SheetNames.indexOf(event.name), 1);
      }
      this.addedZipFiles.splice(this.addedZipFiles.indexOf(event), 1);
      toast.success('File successfully removed from folder');
    },
    exportExcel(addToZip, addAsSheetTab = false) {
      import('xlsx').then(async (xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(this.sensorValues);
        if (addAsSheetTab) {
          if (this.workbook === null) {
            const sheetName = `${this.selectedDevice.name}-${moment(this.fromDate).format('D_MMM_YY')
            }-${moment(this.toDate).format('D_MMM_YY')}`;
            this.workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            this.addedZipFiles.push(
              {
                type: 'XLSX Sheet',
                name: sheetName,
                sensorsCount: this.selectedSensors ? this.selectedSensors.length : this.selectedDevice.sensors.length,
              },
            );
          } else {
            const tabName = `${this.selectedDevice.name}-${moment(this.fromDate).format('D_MMM_YY')
            }-${moment(this.toDate).format('D_MMM_YY')}-${this.workbook.SheetNames.length}`;
            this.workbook.Sheets[tabName] = worksheet;
            this.workbook.SheetNames.push(tabName);
            this.addedZipFiles.push(
              {
                type: 'XLSX Sheet',
                name: tabName,
                sensorsCount: this.selectedSensors ? this.selectedSensors.length : this.selectedDevice.sensors.length,
              },
            );
          }
        } else {
          let { workbook } = this;
          if (!addToZip) {
            workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          }
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          await this.saveAsExcelFile(excelBuffer, addToZip);
        }
      });
    },
    async saveAsExcelFile(buffer, addToZip) {
      await import('file-saver').then(async (FileSaver) => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        if (addToZip) {
          await this.zipFile.file(`${this.selectedDevice.name}-table-${moment(this.fromDate).format('D_MMM_YY')
          }-${
            moment(this.toDate).format('D_MMM_YY')}${EXCEL_EXTENSION}`, data);
        } else {
          FileSaver.saveAs(data,
            `${this.selectedDevice.name
            }-table-${moment(this.fromDate).format('D_MMM_YY')
            }-${
              moment(this.toDate).format('D_MMM_YY')}`);
        }
      });
    },
    async addImageToArchive(imageType, output) {
      let outputTemp = output;
      if (!outputTemp) {
        await this.print();
        outputTemp = this.output;
      }
      const fileName = `${Object.keys(this.zipFile.files).length}-${this.selectedDevice.name}-${imageType}-${moment(this.fromDate).format('D_MMM_YY')
      }-${
        moment(this.toDate).format('D_MMM_YY')}.png`;
      this.zipFile.file(fileName, outputTemp.replace(/^data:image\/(png|jpg);base64,/, ''), { base64: true });
      this.addedZipFiles.push(
        {
          type: 'Image',
          name: fileName,
          sensorsCount: this.selectedSensors ? this.selectedSensors.length : this.selectedDevice.sensors.length,
        },
      );
      toast.success('Image successfully added to folder');
    },
    async addExcelToArchive() {
      await this.exportExcel(true, true);
      toast.success('Excel sheet successfully added to folder');
    },
    async downloadZipFile() {
      const deviceName = this.selectedDevice.name;
      const { fromDate } = this;
      const { toDate } = this;
      await import('file-saver').then(async (FileSaver) => {
        await this.exportExcel(true);
        setTimeout(async () => {
          await this.zipFile.generateAsync({ type: 'blob' })
            .then((content) => {
              FileSaver.saveAs(content, `resources-${deviceName} ${moment(fromDate).format('D_MMM_YY')}-${moment(toDate).format('D_MMM_YY')}.zip`);
              toast.success('Resources successfully exported');
              router.go();
            });
        }, 500);
      });
      this.selectedDevice.lastActive = null;
    },
    async selectCompany($event) {
      this.selectedCompany = $event.value;
      this.devices = this.selectedCompany.devices;
      if (this.devices.length > 0) {
        [this.selectedDevice] = this.devices;
      } else {
        this.selectedDevice = null;
      }
    },
    async selectDevice(device) {
      await this.selectDeviceInStore(device);
    },
    resetSelectedSensors() {
      this.selectedSensors = null;
    },
  },
};
</script>
