import axios from 'axios';
import { useToast } from 'vue-toastification';
/* eslint-disable import/no-cycle */
import router from '../../router';

const toast = useToast();

const users = {
  namespaced: true,
  state: {
    user: null,
    companies: null,
    userLoading: false,
    userRole: null,
    companiesLoading: false,
    hashState: null,
  },
  mutations: {
    setUserLoading(state, loading) {
      state.userLoading = loading;
    },

    setCompaniesLoading(state, loading) {
      state.companiesLoading = loading;
    },

    setUserRole(state, role) {
      state.userRole = role;
    },

    setCompanies(state, companies) {
      state.companies = companies.sort((company1, company2) => company1.devices.length < company2.devices.length);
    },

    setHashState(state, hashState) {
      state.hashState = hashState;
    },

    setUserData(state, userData) {
      state.user = userData;
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    },

    clearUserData(state) {
      state.user = null;
      state.userRole = null;
    },
  },
  actions: {
    async isAdmin({ commit }) {
      commit('setUserLoading', true);
      try {
        await axios.get('/users/is-admin');
      } catch (error) {
        console.log(error);
        commit('setUserLoading', false);
      }
      commit('setUserLoading', false);
    },
    async getUser({ commit, state }, parameters) {
      commit('setUserLoading', true);
      if (parameters.state !== state.hashState) {
        toast.error('An error has occurred please login again');
        commit('setUserLoading', false);
        await router.push({ name: 'Home' });
      }
      try {
        const userId = parameters.user_id;
        let tempUser = await axios.get('/users/get-user-by-id', {
          params: {
            userId,
          },
        });
        tempUser = tempUser.data;
        tempUser.token = parameters.access_token;
        commit('setUserData', tempUser);
        const userData = state.user;
        // If user role is undefined default to regular user
        if (userData.roles[0]) {
          commit('setUserRole', userData.roles[0].name);
        } else {
          commit('setUserRole', 'user');
        }
        commit('setUserLoading', false);
        toast.success('Successfully logged in');
        await router.push({ name: 'Home' });
      } catch (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An unknown error has occurred');
        }
        commit('setUserLoading', false);
        await router.push({ name: 'Home' });
      }
    },
    async authorize({ commit }) {
      /* eslint-disable no-bitwise */
      const hashState = [...Array(40)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
      commit('setHashState', hashState);
      let config = new URLSearchParams({
        client_id: process.env.VUE_APP_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_REDIRECT_URI,
        response_type: 'token',
        scope: '*',
        state: hashState,
      });
      config = config.toString();
      window.location.replace(`${process.env.VUE_APP_SERVER_URL}/oauth/authorize?${config}`);
    },

    async getCompanies({ commit }) {
      commit('setCompaniesLoading', true);
      let data = null;
      try {
        data = await (axios.get('/companies'));
        commit('setCompanies', data.data);
      } catch (error) {
        console.error(error);
        commit('setCompaniesLoading', false);
      }
      commit('setCompaniesLoading', false);
    },

    logout({ commit }) {
      commit('clearUserData');
      // router.go();
      let config = new URLSearchParams({
        url: process.env.VUE_APP_REDIRECT_URI,
      });
      config = config.toString();
      window.location.replace(`${process.env.VUE_APP_SERVER_URL}/logout?${config}`);
      // router.push({ name: 'Home' });
    },

  },
  getters: {
    isLogged: (state) => !!state.user,
  },
};
export default users;
