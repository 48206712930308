/* eslint-disable import/no-cycle */
import { createRouter, createWebHistory } from 'vue-router';
import { useToast } from 'vue-toastification';
import Home from '../views/Home.vue';
import Overview from '../views/Overview.vue';
import DataFeed from '../views/DataFeed.vue';
import AdminPanel from '../views/AdminPanel.vue';
import Reports from '../views/Reports.vue';
import Create from '../views/Create.vue';
import Callback from '../views/Callback.vue';
import store from '../state';
import Help from '../views/Help.vue';
import Analytics from '../views/Analytics.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/create-resources',
    name: 'Create',
    component: Create,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/admin-panel',
    name: 'Admin Panel',
    component: AdminPanel,
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/data-feed',
    name: 'DataFeed',
    component: DataFeed,
  },
  {
    path: '/auth/callback',
    name: 'Callback',
    component: Callback,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const toast = useToast();

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['users/isLogged'];
  if (!loggedIn && to.name !== 'Callback') {
    toast.error('You are not logged in. Redirecting you to the login page!');
    store.dispatch('users/authorize');
  } else {
    next();
  }
});

export default router;
