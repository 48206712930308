<template>
  <div>
    <div class="nav d-flex">
      <div class="logo mr-auto my-auto">
        <a class="ml-3" href="/">
          <img
                  class="mcl-logo"
                  alt="MCL logo"
                  src="@/assets/mcl.png"
                  style="width:100px;height:100px;"
          />
        </a>
      </div>
      <div class="navbar">
        <pv-button
                class="btn btn-nav"
                v-bind:class="{ disabled: isActive('Home') }"
                @click="redirectTo('/')"
                label="Home"
        ></pv-button>
        <pv-button
                class="btn btn-nav"
                v-bind:class="{ disabled: isActive('Overview') }"
                @click="redirectTo('overview')"
                label="Overview"
        ></pv-button>
        <pv-button
                class="btn btn-nav"
                v-bind:class="{ disabled: isActive('DataFeed') }"
                @click="redirectTo('data-feed')"
                label="Data Feed"
        ></pv-button>
        <pv-button
                class="btn btn-nav"
                v-bind:class="{ disabled: isActive('Reports') }"
                @click="redirectTo('reports')"
                label="Reports"
        ></pv-button>
        <pv-button
            class="btn btn-nav"
            v-bind:class="{ disabled: isActive('Help') }"
            @click="redirectTo('help')"
            label="Help"
        ></pv-button>
        <pv-button
                v-if="isAdmin"
                class="btn btn-nav"
                v-bind:class="{ disabled: isActive('Admin Panel') }"
                @click="redirectTo('admin-panel')"
                label="Admin Panel"
        ></pv-button>
        <pv-button
            v-if="isAdmin"
            class="btn btn-nav"
            v-bind:class="{ disabled: isActive('Create') }"
            @click="redirectTo('create-resources')"
            label="Create"
        ></pv-button>
        <pv-button
            v-if="isAdmin"
            class="btn btn-nav"
            v-bind:class="{ disabled: isActive('Analytics') }"
            @click="redirectTo('analytics')"
            label="Analytics"
        ></pv-button>
      </div>
      <div class="ml-auto navbar">
        <pv-button
                class="btn btn-nav h-100"
                @click="logout()"
                icon="pi pi-users"
                label="Logout"
                v-if="isLogged"
        ></pv-button>
        <pv-button
                class="btn btn-nav h-100"
                v-bind:class="{ disabled: isActive('Login') }"
                @click="redirectTo('login')"
                icon="pi pi-users"
                label="Login"
                v-else
        ></pv-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import router from '../router';

export default {
  name: 'TopNav',
  data() {
    return {
      analyticsDisabled: null,
    };
  },
  updated() {
    this.disableAnalytics();
  },
  setup() {
    const store = useStore();
    const userData = store.state.users.user;
    if (userData) {
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    }
    return {
      isLogged: computed(() => store.getters['users/isLogged']),
      userRole: computed(() => store.state.users.userRole),
      logout: () => store.dispatch('users/logout'),
    };
  },
  computed: {
    isAdmin() {
      if (this.userRole) {
        return this.userRole === 'admin';
      }
      return null;
    },
    activeItem() {
      let activeItem = null;
      if (this.$route.matched && this.$route.matched.length > 0) {
        activeItem = this.$route.matched[0].name;
      }
      return activeItem;
    },
  },
  methods: {
    disableAnalytics() {
      if (this.isAdmin && !this.analyticsDisabled) {
        const exclude = document.createElement('script');
        exclude.setAttribute('src', 'https://plausible.io/js/plausible.exclusions.js');
        let domain = process.env.VUE_APP_SERVER_URL;
        domain = domain.slice((domain.lastIndexOf('/') + 1));
        exclude.setAttribute('data-domain', domain);
        exclude.setAttribute('data-exclude', '*');
        exclude.setAttribute('defer', '');
        document.head.appendChild(exclude);
        this.analyticsDisabled = true;
      } else if (this.isAdmin === false && (this.analyticsDisabled === null || this.analyticsDisabled)) {
        const exclude = document.createElement('script');
        exclude.setAttribute('src', 'https://plausible.io/js/plausible.js');
        let domain = process.env.VUE_APP_SERVER_URL;
        domain = domain.slice((domain.lastIndexOf('/') + 1));
        exclude.setAttribute('data-domain', domain);
        exclude.setAttribute('defer', '');
        document.head.appendChild(exclude);
        this.analyticsDisabled = false;
      }
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    redirectTo(path) {
      if (this.isLogged) {
        router.push(path);
      } else {
        router.push({ name: 'Login' });
      }
    },
  },
};
</script>
