<template>
  <div v-if="!loading">
    <div v-if="selectedDevice && selectedDevice.model.length > 0" class="row m-0 mt-4 vtk-container">
      <div class="col-11 mx-auto">
        <div>
          <vtk :devices="devices"></vtk>
        </div>
      </div>
    </div>

    <div class="d-flex flex-sm-col-reverse flex-lg-row py-4">
        <div class="col-12 col-md-11 col-lg-11 row m-0 mx-auto">
          <div class="col-12 col-lg-5 pr-2 px-0 mt-3 mt-lg-0 flex-grow-1 bg-white-gray-text">
            <data-view class="my-auto h-100" :value="alarms" layout="grid" :paginator="alarms.length >  0 ? true : false" :rows="1" >
              <template #header>
                <div class="d-flex">
                  <h5 class="ml-3 my-4"
                      v-tooltip="'Click anywhere on the alarm to see it on the graph'"
                  >Alarm Feed <i class="fa fa-info-circle small"></i></h5>
                  <device-selector
                    @device-changed="selectDevice($event)"
                    :loading="loading"
                    :options="devices"
                    :initial-device="selectedDevice"
                    :classProp="'ml-auto my-auto device-selector'" >
                  </device-selector>
                </div>
              </template>
              <template v-if="!alarmsLoading" #grid="slotProps">
                <div class="p-col-12 p-md-4 cursor-pointer" @click="selectAlarm(slotProps)">
                  <div class="product-grid-item card">
                    <div class="row m-0 my-4">
                      <div class="col-12 col-sm-6 col-xl-6" v-if="selectedDevice.camera_enabled">
                        <img :src="slotProps.data.gifUrl" class="mh-225 mw-100 rounded" :alt="slotProps.data.name"/>
                      </div>

                      <div class="col-12 col-lg-12 mx-sm-auto text-left" :class="selectedDevice.camera_enabled ? 'col-md-6 col-xl-6' : ''">
                        <div class="row m-0">
                          <div class="col-5">
                            <p>Time:</p>
                            <div class="font-weight-bold">{{slotProps.data.time}}</div>
                          </div>
                          <div class="col-7">
                            <p>Originator:</p>
                            <div class="font-weight-bold">{{slotProps.data.originator}}</div>
                          </div>
                        </div>
                        <div class="row m-0 mt-5">
                          <div class="col-5">
                            <p>Status:</p>
                            <div v-for="status in alarmStatuses" v-bind:key="status.status">
                              <div class="font-weight-bold" v-if="status.status === slotProps.data.status">
                                {{status.label}}
                              </div>
                            </div>
                          </div>
                          <div class="col-7">
                            <p>Note:</p>
                            <div class="font-weight-bold">{{slotProps.data.note}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else #grid="">
                <div class="p-col-12 p-md-4 my-5 cursor-pointer">
                  <h4 class="h-100 my-5 py-5 mx-auto">Loading alarms <i class='fa fa-circle-o-notch fa-spin'></i></h4>
                </div>
              </template>
              <template #empty>
                <div class="d-flex py-5 h-100">
                  <h4 v-if="!selectedDevice" class="my-5 mx-auto">Select a device to see it's alarms</h4>
                  <h4 v-else class="my-5 mx-auto"> No alarms have been raised for this device </h4>
                </div>
              </template>
            </data-view>
          </div>
          <div class="col-12 col-lg-7 ml-auto px-0 flex-grow-1">
            <div class="d-flex flex-column bg-white-gray-text px-4 pb-3 h-100">
              <h5 class="text-black mr-auto my-4">Sensor feed</h5>
              <div class="row m-0 mb-2">
                <dropdown
                    v-if="!dataLoading && selectedDevice"
                    v-model="selectedTime"
                    :options="timePeriods"
                    class="mr-auto"
                    @change="getCorrectDataForTime()"
                    optionLabel="time"
                    placeholder="Select a Time-period..."
                />
                <dropdown
                    v-else-if="dataLoading || !selectedDevice"
                    v-model="selectedTime"
                    :options="timePeriods"
                    class="mr-auto"
                    @change="getCorrectDataForTime()"
                    optionLabel="time"
                    placeholder="Select a Time-period..."
                    disabled
                />
                <pv-button
                    v-if="alarmSelected"
                    @click="getCorrectDataForTime()"
                    class="ml-auto"
                    icon="pi pi-refresh"
                    label="Back to graph"
                    v-tooltip="'Returns to view of all sensors'"
                />
                <pv-button
                    v-else-if="!dataLoading && selectedDevice"
                    @click="getLatestDataAndAlarms()"
                    class="ml-auto"
                    icon="pi pi-refresh"
                    label="Latest data"
                    v-tooltip="'Get latest data from ThingsBoard'"
                />
                <pv-button
                    v-else-if="dataLoading || !selectedDevice"
                    class="ml-auto disabled"
                    :icon="selectedDevice ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-refresh'"
                    label="Latest data"
                    disabled
                />
              </div>
              <div class="sensor-chart h-100" v-if="selectedDevice && !chartLoading">
                <div v-if="telemetry">
                  <telemetry-chart
                      :telemetry="telemetry"
                      :selected-device="selectedDevice"
                      :alarm-selected="alarmSelected"
                      :clicked-alarm="clickedAlarm"
                  ></telemetry-chart>
                </div>
                <div class="d-flex h-100 row" v-else>
                  <div class="col-12 d-flex">
                    <h4 class="my-auto mx-auto">This device seems to be offline</h4>
                  </div>
                  <div v-if="selectedDevice.lastActive" class="col-12 mt-auto">
                    <h5 class="mx-auto" v-tooltip="'Date format used is D/M/Y throughout the website'">It was last active on: {{ selectedDevice.lastActive }}</h5>
                  </div>
                </div>
              </div>
              <div v-else-if="chartLoading" class="d-flex flex-column bg-white-gray-text px-4 h-100">
                <h4 class="my-auto">
                  Loading chart info <i class='fa fa-circle-o-notch fa-spin'></i>
                </h4>
              </div>
              <div v-else class="d-flex flex-column bg-white-gray-text px-4 h-100">
                <h4 class="my-auto">
                  Select a device from the list to see it's telemetry
                </h4>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <h4 class="mt-5" v-else>
    Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
  </h4>
</template>

<script>
import { computed, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import moment from 'moment';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Vtk from '../components/Vtk';
import TelemetryChart from '../components/TelemetryChart';
import DeviceSelector from '../components/DeviceSelector.vue';

export default {
  setup() {
    const chart = ref(null);
    const store = useStore();

    return {
      loadDevices: () => store.dispatch('devices/loadDevices'),
      refreshAlarms: (deviceId) => store.dispatch('alarms/refreshAlarms', deviceId),
      retrieveLatestData: (selectedDevice) => store.dispatch(
        'devices/retrieveLatestData',
        {
          selectedDevice,
        },
      ),
      mapAlarms: (alarmsToMap) => store.dispatch(
        'alarms/mapAlarms',
        {
          alarmsToMap,
        },
      ),
      retrieveDataForPeriod: (config) => store.dispatch(
        'devices/retrieveDataForPeriod', {
          config,
        },
      ),
      selectDeviceInStore: (device) => store.commit('devices/setSelectedDevice', device),
      selectCurrentAlarm: (alarm) => store.commit('alarms/setCurrentAlarm', alarm),
      selectedDevice: computed(() => store.state.devices.selectedDevice),
      currentAlarm: computed(() => store.state.alarms.currentAlarm),
      alarmsLoading: computed(() => store.state.alarms.alarmsLoading),
      dataLoading: computed(() => store.state.devices.devicesDataLoading),
      alarms: computed(() => store.state.alarms.alarms),
      devices: computed(() => store.state.devices.devices),
      chart,
    };
  },
  data() {
    return {
      loading: true,
      chartLoading: false,
      selectedTime: null,
      telemetry: null,
      alarmSelected: false,
      clickedAlarm: null,
      alarmStatuses: [
        { status: 'CLEARED_ACK', color: 'success', label: 'Cleared' },
        { status: 'CLEARED_UNACK', color: 'success', label: 'In Progress' },
        { status: 'ACTIVE_UNACK', color: 'secondary', label: 'In Progress' },
        { status: 'ACTIVE_ACK', color: 'info', label: 'In Progress' },
      ],
      timePeriods: [
        { time: 'Live', period: 60000, interval: 6000 },
        {
          time: '2 Hours', period: 2, unit: 'Hours', interval: 600000,
        },
        {
          time: '1 Day', period: 1, unit: 'Days', interval: 3600000,
        },
        {
          time: '7 Days', period: 7, unit: 'Days', interval: 21600000,
        },
        {
          time: '2 Weeks', period: 2, unit: 'Weeks', interval: 43200000,
        },
        {
          time: '1 Month', period: 1, unit: 'Months', interval: 86400000,
        },
        {
          time: '3 Months', period: 3, unit: 'Months', interval: 259200000,
        },
        {
          time: '6 Months', period: 6, unit: 'Months', interval: 518400000,
        },
        {
          time: '1 Year', period: 1, unit: 'Years', interval: 1036800000,
        },
        {
          time: '3 Years', period: 3, unit: 'Years', interval: 3110400000,
        },
        {
          time: '5 Years', period: 5, unit: 'Years', interval: 5184000000,
        },
      ],
    };
  },
  components: {
    TelemetryChart,
    Vtk,
    DeviceSelector,
  },
  async mounted() {
    this.loading = true;
    await this.loadDevices();
    if (this.selectedDevice) {
      await this.getLatestDataAndAlarms();
      await this.mapAlarms(this.selectedDevice.alarms);
      setInterval(async () => {
        if (
          this.$route.matched[0].name === 'DataFeed'
              && !this.selectedDevice.lastActive
              && !this.alarmSelected
              && this.selectedTime !== null
              && this.selectedTime === this.timePeriods[0]
              && !this.loading
        ) {
          await this.getLatestDataAndAlarms();
          if (this.$refs.chart) {
            this.$refs.chart.refresh();
          }
        }
      }, 60000);
    }
    this.loading = false;
  },
  methods: {
    async getDataForPeriod(selectedTimeFrame) {
      const config = {
        params: {
          ts: moment().format('x'),
          deviceId: this.selectedDevice.uuid,
          selectedPeriod: selectedTimeFrame.period,
          unit: selectedTimeFrame.unit,
          interval: selectedTimeFrame.interval,
        },
      };
      const tempTelemetry = await this.retrieveDataForPeriod(config);

      if (Object.keys(tempTelemetry).length > 0 && !tempTelemetry.errorCode) {
        this.telemetry = tempTelemetry;
      } else if (Object.keys(tempTelemetry).length === 0) {
        this.selectedDevice.lastActive = moment(tempTelemetry).format('DD/MM/Y HH:mm');
      } else {
        this.selectedDevice.error = true;
      }
    },
    async getLatestDataAndAlarms() {
      await this.getLatestData();
      await this.refreshAlarms(this.selectedDevice.uuid);
    },
    async getLatestData() {
      if (this.selectedTime !== this.timePeriods[0] || this.alarmSelected) {
        this.chartLoading = true;
      }
      const tempTelemetry = await this.retrieveLatestData(this.selectedDevice);
      if (Object.keys(tempTelemetry).length > 0 && !tempTelemetry.errorCode) {
        this.telemetry = tempTelemetry;
      } else if (Object.keys(tempTelemetry).length === 0) {
        this.selectedDevice.lastActive = moment(tempTelemetry).format('DD/MM/Y HH:mm');
      } else {
        this.selectedDevice.error = 'Not working';
      }
      if (this.selectedTime !== this.timePeriods[0] || this.alarmSelected) {
        [this.selectedTime] = this.timePeriods;
        this.chartLoading = false;
        this.alarmSelected = false;
      }
    },
    async selectAlarm($event) {
      this.chartLoading = true;
      await this.selectCurrentAlarm(null);
      const config = {
        params: {
          orig: $event.data.originator,
          ts: $event.data.ts,
          deviceId: this.selectedDevice.uuid,
          limit: 5,
          selectedPeriod: 60000,
          interval: 1000,
          alarm: true,
        },
      };
      this.alarmSelected = true;
      this.clickedAlarm = $event;
      this.telemetry = await this.retrieveDataForPeriod(config);
      this.alarmSelected = true;
      this.chartLoading = false;
    },
    async getCorrectDataForTime() {
      this.chartLoading = true;
      await this.selectCurrentAlarm(null);
      this.alarmSelected = false;
      if (this.selectedTime === null) {
        [this.selectedTime] = this.timePeriods;
      }
      if (this.selectedTime.time === 'Live') {
        await this.getLatestData();
      } else {
        await this.getDataForPeriod(this.selectedTime);
      }
      this.chartLoading = false;
    },
    async selectDevice(device) {
      this.loading = true;
      await this.selectDeviceInStore(device);
      await this.refreshAlarms(this.selectedDevice.uuid);
      await this.getCorrectDataForTime();
      this.alarmSelected = false;
      this.loading = false;
    },
  },

  computed: {
    ...mapState('alarms', ['alarms']),
  },
};
</script>
